<template>
  <SpeechList
    is-super
  />
</template>

<script>
import SpeechList from "@/pages/speech/SpeechList";
export default {
  name: "SupperSpeech",
  components: {
    SpeechList
  }
}
</script>

<style scoped>

</style>
